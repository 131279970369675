import React from "react";

// Images
// TODO: Use gatsby-image for these
import imageRoss from "../../assets/images/rgreenhalf.png";
import imageJames from "../../assets/images/jallardice.png";
import imageEngineering from "../../assets/images/engineering.jpg";
import imageDesign from "../../assets/images/design.jpg";
import imageProduct from "../../assets/images/product_management.jpg";
import Member from "./partials/Member";

const TeamSection = () => {
	const membersDataStatic = [
		{
			image: imageRoss,
			name: "Ross Greenhalf",
			role: "Co-founder / CEO",
			text: "Ex-head of software at Tesco, now running Orange Jellyfish delivering solutions incrementally at pace and trying to instil a better software craftsmanship mindset.",
			offset: "4rem",
		},
		{
			image: imageJames,
			name: "James Allardice",
			role: "Co-founder / CTO",
			text: "Co-founder of Orange Jellyfish. Passionate about building things at scale, especially with JavaScript. International speaker and tech advocate. Open source maintainer and contributor.",
			offset: "4rem",
		},
		{
			image: imageEngineering,
			name: "Engineering",
			text: "Our world-class engineering team is made up of experienced software developers and technical leads. Everyone understands the benefits of pair programming and collaborative code reviews and everyone takes advantage of our training perks.",
			offset: "1rem",
		},
		{
			image: imageDesign,
			name: "Product Design",
			text: "Our designers have a wealth of experience across user interface, user experience and product design. They’re all big fans of validating their ideas with real customers and work closely with the engineering team to bring those ideas to life.",
			offset: "6rem",
		},
		{
			image: imageProduct,
			name: "Product Management",
			text: "Our product managers are experts in creating world-class digital products, coordinating engineering, design and business stakeholders. Masters of data, they skillfully balance the delivery of product features, technical debt and overall outcomes.",
			offset: "0",
		},
	];

	return (
		<section className="section-team">
			<div className="container">
				<h2>The team</h2>
				<div className="row">
					{membersDataStatic.map((member, key) => {
						return <Member key={key} image={member.image} name={member.name} role={member.role} text={member.text} offset={member.offset} index={key} />;
					})}
				</div>
			</div>
		</section>
	);
};

export default TeamSection;
