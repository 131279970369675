import React from "react";
import PropTypes from "prop-types";
import Image from "../../elements/Image";
import ScrollAnimation from "react-animate-on-scroll";

// Animate.css
import "animate.css/animate.min.css";

const propTypes = {
	image: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	offset: PropTypes.string.isRequired,
};

const defaultProps = {
	image: undefined,
	name: undefined,
	role: undefined,
	text: undefined,
	offset: "0",
};

const Member = ({ ...props }) => {
	return (
		<>
			<div className="col-lg-4 col-md-6">
				<ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
					<div className="section-team__box" style={{ marginTop: props.offset }}>
						<div className="section-team__box--img">
							<Image src={props.image} alt="Avatar" />
						</div>
						<div className="section-team__box__content">
							<h5>{props.name}</h5>
							<div className="section-team__box__content--role">{props.role}</div>
							<div className="section-team__box__content--text">{props.text}</div>
						</div>
					</div>
				</ScrollAnimation>
			</div>
			{props.index === 1 && <div />}
		</>
	);
};

Member.propTypes = propTypes;
Member.defaultProps = defaultProps;

export default Member;
