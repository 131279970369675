import React from "react";

// Sections
import Hero from "../../components/sections/Hero";
import HeroSeparator from "../../components/sections/HeroSeparator";
import TeamSection from "../../components/sections/TeamSection";
import Cta from "../../components/sections/Cta";
import Layout from "../../layouts";
// Images
import bgHero from "../../assets/images/bg-team.jpg";
import logo from "../../assets/images/logo-orangejellyfish-text-big.svg";
import lineImg from "../../assets/images/team-icon.svg";

const Team = () => {
	return (
		<Layout>
			<Hero hero={bgHero} layout="extended">
				<div className="container h-100 ">
					<div className="hero__inner">
						<h1 className="h1">
							<span>Hello,</span>
							<span>we're</span>
							<span>
								<img src={logo} alt="orangejellyfish" className="img-fluid" />
							</span>
						</h1>
						<div className="hero__content">
							<p>
								We're a close-knit bunch of software engineers, project designers, product managers and more. We're based in the UK but come from a range of different backgrounds from all over the world.
							</p>
							<p>
								When new people join us, it isn't so much about the culture fit as the culture add. Because we believe diversity brings different skills, experiences and opinions – making our work even better.
							</p>
						</div>
					</div>
				</div>
			</Hero>
			<HeroSeparator image={lineImg} />
			<TeamSection />
			<Cta />
		</Layout>
	);
};

export default Team;
